testing 
<div class="common-layout {{ 'is-' + selectedHeaderColor }}" 
    [ngClass]="{
        'is-folded': isFolded,
        'is-side-nav-dark' : isSideNavDark,
        'is-expand' : isExpand
    }"
>
  
<app-header-backoffice></app-header-backoffice>
<app-sidenav></app-sidenav>
    <div class="page-container dasboard-container">
        <div class="main-content">
            <div class="main-content-header" *ngIf="contentHeaderDisplay !== 'none'">
                <div class="container">
                    <h4 class="page-title" *ngIf="breadcrumbs$ | async; let breadcrumbs">{{(breadcrumbs[breadcrumbs.length - 1].label)}}</h4>
                    <nz-breadcrumb nzSeparator=">" class="breadcrumb-link">
                        <i class="m-r-5" nz-icon nzType="home"></i>
                        <nz-breadcrumb-item *ngFor="let breadcrumb of breadcrumbs$ | async">
                            <a [routerLink]="breadcrumb.url">
                                {{ breadcrumb.label }}
                            </a>
                        </nz-breadcrumb-item>
                    </nz-breadcrumb>
                </div>
            </div>
            <router-outlet></router-outlet>
            <app-footer></app-footer>
        </div>
        
    </div>
</div>