import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/shared/services/user.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { Post } from 'src/app/shared/interfaces/social-posting-post.type';
import { AuthService } from 'src/app/shared/services/authentication.service';
import { User } from 'src/app/shared/interfaces/user.type';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BackofficeSocialPostingService {
  currentUser: User;
  userId;
  postImagePath = 'posts';
  postsCollection = 'posts';
  publishersCollection = 'publishers';
  socialPlatformsStatus: any = {};
  publishersList = [];

  constructor(
    private http: HttpClient,
    private db: AngularFirestore,
    private userService: UserService,
    private storage: AngularFireStorage,
    private authService: AuthService,
  ) {
    this.authService.getAuthState().subscribe(async (user) => {
      if (!user) {
        return;
      }

      this.userId = user.uid;

      this.updateSocialPlatformsStatus();
      this.populatePublishersData().subscribe((data) => {
        this.publishersList = data;
      });
    });
  }

  updateSocialPlatformsStatus() {
    return new Promise((resolve) => {
      this.userService.get(this.userId).subscribe((user: User) => {
        this.currentUser = user;
        const currentDate = new Date();
        if (user && user.facebook) {
          const fbData = user.facebook;
          fbData.expires_at && fbData.expires_at > currentDate.getTime()
            ? (this.socialPlatformsStatus['facebook'] = true)
            : (this.socialPlatformsStatus['facebook'] = false);
        } else {
          this.socialPlatformsStatus['facebook'] = false;
        }

        if (user && user.linkedin) {
          const data = user.linkedin;
          data.expires_at && data.expires_at > currentDate.getTime()
            ? (this.socialPlatformsStatus['linkedin'] = true)
            : (this.socialPlatformsStatus['linkedin'] = false);
        } else {
          this.socialPlatformsStatus['linkedin'] = false;
        }

        if (user && user.twitter) {
          const data = user.twitter;
          data.token_secret && data.profile_data
            ? (this.socialPlatformsStatus['twitter'] = true)
            : (this.socialPlatformsStatus['twitter'] = false);
        } else {
          this.socialPlatformsStatus['twitter'] = false;
        }

        resolve(true);
      });
    });
  }

  saveAuthTokenToServer(provider, data) {
    this.userService.getCurrentUser().then((user) => {
      this.currentUser = {
        id: user.uid,
        email: user.email,
        avatar: user.photoURL,
        fullname: user.displayName,
      };
      if (this.currentUser.id) {
        this.http
          .post(
            `${environment.baseAPIDomain}/api/v1/users/${this.currentUser.id}/tokens/${provider}`,
            data,
          )
          .subscribe(
            (result) => {
              // console.log(`Updated user notification_token for user : ${this.currentUser.id}`, result);
            },
            (err) => {
              // console.error(`Failed to update notification_token for user : ${this.currentUser.id}`, err);
            },
          );
      }
    });
  }

  addNewPost(postData) {
    return new Promise((resolve, reject) => {
      this.http
        .post(environment.baseAPIDomain + `/api/v1/posts`, postData)
        .subscribe(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }

  updatePost(postId: string, postData) {
    return new Promise((resolve, reject) => {
      return this.http
        .put(environment.baseAPIDomain + `/api/v1/posts/${postId}`, postData)
        .subscribe(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }

  deletePost(postId) {
    return this.http.delete(
      environment.baseAPIDomain + `/api/v1/posts/${postId}`,
    );
  }

  addPostImage(imageDetails: any) {
    const path = `${this.postImagePath}/${Date.now()}_${imageDetails.file.name}`;
    return new Promise((resolve, reject) => {
      this.storage
        .upload(path, imageDetails.file)
        .then((snapshot) => {
          snapshot.ref
            .getDownloadURL()
            .then((downloadURL) => {
              resolve({ image: { url: downloadURL } });
            })
            .catch((err) => reject(err));
        })
        .catch((error) => reject(error));
    });
  }

  getPostsOnScroll(
    publisherId: string,
    limit: number,
    navigation: string,
    lastVisible,
  ) {
    let dataQuery = this.db.collection<Post[]>(
      `${this.postsCollection}`,
      (ref) =>
        ref
          .where('member_id', '==', publisherId)
          .orderBy('scheduled_date', 'desc')
          .limit(limit),
    );

    if (navigation === 'next') {
      dataQuery = this.db.collection<Post[]>(`${this.postsCollection}`, (ref) =>
        ref
          .where('member_id', '==', publisherId)
          .orderBy('scheduled_date', 'desc')
          .limit(limit)
          .startAfter(lastVisible),
      );
    }

    return dataQuery.snapshotChanges().pipe(
      map((actions) => {
        return {
          postList: actions.map((a) => {
            const data: any = a.payload.doc.data();
            return data;
          }),
          lastVisible:
            actions && actions.length < limit
              ? null
              : actions[actions.length - 1].payload.doc,
        };
      }),
    );
  }

  deleteTokenInfo(providerName: string) {
    return new Promise((resolve, reject) => {
      if (this.currentUser.id) {
        this.http
          .post(
            environment.baseAPIDomain +
              `/api/v1/users/${this.currentUser.id}/unlinkSocialAccess`,
            { provider: providerName },
          )
          .subscribe(
            (result) => {
              resolve(result);
            },
            (err) => {
              reject(false);
            },
          );
      } else {
        reject(false);
      }
    });
  }

  getCurrentUsersFacebookGroups() {
    return new Promise((resolve, reject) => {
      const currentDate = new Date();
      if (this.currentUser.facebook) {
        const fbData = this.currentUser.facebook;
        if (fbData.expires_at && fbData.expires_at > currentDate.getTime()) {
          this.http
            .get(
              `https://graph.facebook.com/me/groups?access_token=${fbData.token}&admin_only=true`,
            )
            .subscribe(
              (result) => {
                resolve(result);
              },
              (err) => {
                resolve([]);
              },
            );
        } else {
          resolve([]);
        }
      } else {
        resolve([]);
      }
    });
  }

  getCurrentUsersFacebookPages() {
    return new Promise((resolve, reject) => {
      const currentDate = new Date();
      if (this.currentUser.facebook) {
        const fbData = this.currentUser.facebook;
        if (fbData.expires_at && fbData.expires_at > currentDate.getTime()) {
          this.http
            .get(
              `https://graph.facebook.com/me/accounts?access_token=${fbData.token}`,
            )
            .subscribe(
              (result) => {
                resolve(result);
              },
              (err) => {
                resolve([]);
              },
            );
        } else {
          resolve([]);
        }
      } else {
        resolve([]);
      }
    });
  }

  populatePublishersData() {
    return this.db
      .collection(this.publishersCollection, (ref) =>
        ref.where('isPlatform', '==', true),
      )
      .valueChanges()
      .pipe(take(1));
  }
}
