import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/authentication.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-invite-button',
  templateUrl: './share-button.component.html',
  styleUrls: ['./share-button.component.scss']
})
export class ShareButtonComponent implements OnInit, AfterViewChecked {
  articles: any;
  public isActiveModal: boolean;
  public isActiveSuccessBox = false;
  public isMTSCircleLogoCodeCopied = false;
  public isMTSSquareLogoCodeCopied = false;
  isLoaded = false;
  userDetails;
  mtsCircleLogoCode = '';
  mtsSquareLogoCode = '';
  constructor(
    public authService: AuthService,
    private router: Router
  ) { }
  baseUrl = environment.baseDomain;
  ngOnInit(): void {

    this.authService.getAuthState().subscribe(async (user) => {
      if (!user) {
        return;
      }
      this.userDetails = await this.authService.getLoggedInUserDetails();
      this.mtsCircleLogoCode =
        `<div style="cursor: pointer;max-width: 40px;width: 40px;margin: 0px;padding: 0px;">
          <a href="${environment.baseDomain}${this.userDetails.slug}" target="_blank" style="width: 50%;margin-bottom: 20px;text-decoration: none;">
              <div>
                  <button type="button" style="border: 0px;background-color: transparent;outline: none;display: flex;align-items: center;cursor: pointer;">
                      <svg width="40" height="40" style="max-width: 40px;margin-right: 10px;" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="20" cy="20" r="20" fill="#FF4020"/>
                          <path d="M14.392 10.596C14.728 11.212 15.1107 11.9773 15.54 12.892C15.988 13.788 16.4453 14.768 16.912 15.832C17.3973 16.8773 17.8733 17.9507 18.34 19.052C18.8067 20.1533 19.2453 21.1893 19.656 22.16C20.0667 21.1893 20.5053 20.1533 20.972 19.052C21.4387 17.9507 21.9053 16.8773 22.372 15.832C22.8573 14.768 23.3147 13.788 23.744 12.892C24.192 11.9773 24.584 11.212 24.92 10.596H28.896C29.0827 11.884 29.2507 13.3307 29.4 14.936C29.568 16.5227 29.708 18.184 29.82 19.92C29.9507 21.6373 30.0627 23.364 30.156 25.1C30.268 26.836 30.3613 28.4693 30.436 30H26.18C26.124 28.1147 26.0493 26.0613 25.956 23.84C25.8627 21.6187 25.7227 19.3787 25.536 17.12C25.2 17.904 24.8267 18.772 24.416 19.724C24.0053 20.676 23.5947 21.628 23.184 22.58C22.792 23.532 22.4093 24.4467 22.036 25.324C21.6627 26.1827 21.3453 26.92 21.084 27.536H18.032C17.7707 26.92 17.4533 26.1827 17.08 25.324C16.7067 24.4467 16.3147 23.532 15.904 22.58C15.512 21.628 15.1107 20.676 14.7 19.724C14.2893 18.772 13.916 17.904 13.58 17.12C13.3933 19.3787 13.2533 21.6187 13.16 23.84C13.0667 26.0613 12.992 28.1147 12.936 30H8.68C8.75467 28.4693 8.83867 26.836 8.932 25.1C9.044 23.364 9.156 21.6373 9.268 19.92C9.39867 18.184 9.53867 16.5227 9.688 14.936C9.856 13.3307 10.0333 11.884 10.22 10.596H14.392Z" fill="white"/>
                      </svg>
                  </button>
              </div>
          </a>
      </div>`;

      this.mtsSquareLogoCode =
        `<div style="cursor: pointer;max-width: 40px;width: 40px;margin: 0px;padding: 0px;">
          <a href="${environment.baseDomain}${this.userDetails.slug}" target="_blank" style="width: 50%;margin-bottom: 20px;text-decoration: none;">
              <div>
                  <button type="button" style="border: 0px;background-color: transparent;outline: none;display: flex;align-items: center;cursor: pointer;">
                      <svg width="40" height="40" viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="1024" height="1024" rx="40" fill="#EF4D26"/>
                          <path d="M300.808 161.684C315.651 187.321 332.18 218.693 350.396 255.8C368.612 292.907 387.165 332.037 406.056 373.192C424.947 413.672 443.5 455.164 461.716 497.668C480.607 539.497 497.811 578.291 513.328 614.048C528.845 578.291 545.712 539.497 563.928 497.668C582.144 455.164 600.697 413.672 619.588 373.192C638.479 332.037 657.032 292.907 675.248 255.8C693.464 218.693 709.993 187.321 724.836 161.684H840.204C846.276 212.959 852.011 267.607 857.408 325.628C862.805 382.975 867.528 442.008 871.576 502.728C876.299 562.773 880.347 623.493 883.72 684.888C887.768 745.608 891.141 804.979 893.84 863H768.352C765.653 781.365 762.28 697.369 758.232 611.012C754.859 524.655 749.461 440.996 742.04 360.036C734.619 375.553 725.848 394.107 715.728 415.696C705.608 437.285 694.813 460.899 683.344 486.536C671.875 511.499 660.068 537.473 647.924 564.46C636.455 591.447 624.985 617.759 613.516 643.396C602.721 668.359 592.601 691.972 583.156 714.236C573.711 735.825 565.615 754.379 558.868 769.896H463.74C456.993 754.379 448.897 735.488 439.452 713.224C430.007 690.96 419.549 667.347 408.08 642.384C397.285 616.747 385.816 590.435 373.672 563.448C362.203 536.461 350.733 510.487 339.264 485.524C327.795 460.561 317 437.285 306.88 415.696C296.76 393.432 287.989 374.879 280.568 360.036C273.147 440.996 267.412 524.655 263.364 611.012C259.991 697.369 256.955 781.365 254.256 863H128.768C131.467 804.979 134.503 744.933 137.876 682.864C141.924 620.795 145.972 559.4 150.02 498.68C154.743 437.285 159.803 377.915 165.2 320.568C170.597 263.221 176.332 210.26 182.404 161.684H300.808Z" fill="white"/>
                      </svg>
                  </button>
              </div>
          </a>
      </div>`;
    });
  }
  ngAfterViewChecked(): void {
    if (!this.isLoaded) {
      delete window['addthis'];
      setTimeout(() => { this.loadScript(); }, 100);
      this.isLoaded = true;
    }
  }

  /* To copy Text from Textbox */
  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.isActiveSuccessBox = true;
    setTimeout(() => {
      this.isActiveSuccessBox = false;
    }, 2000);
  }

  copyMTSSquareLogoCode(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.isMTSSquareLogoCodeCopied = true;
    setTimeout(() => {
      this.isMTSSquareLogoCodeCopied = false;
    }, 2000);
  }

  copyMTSCircleLogoCode(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.isMTSCircleLogoCodeCopied = true;
    setTimeout(() => {
      this.isMTSCircleLogoCodeCopied = false;
    }, 2000);
  }

  public openShareModal(): void {
    this.isActiveModal = true;
  }

  public closeShareModal(): void {
    this.isActiveModal = false;
  }
  public loadScript() {
    const node = document.createElement('script');
    node.src = environment.addThisScript;
    node.type = 'text/javascript';
    node.async = true;
    // node.charset = 'utf-8'; charset is now obsolete attribute, so safely removed this attribute.
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
