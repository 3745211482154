import {
    ADVISOR, AUTHOR, BLOGGER, CHARITY, COMPANY, CUSTOMERSERVICE, CUSTOMERSERVICEADMIN, ECOMMERCE,
    FUNDRAISER, GUESTPOST, INFLUENCERMARKETPLACE, JOB, MEMBER, ONLINECOURSE, ONLINESTORE, PAIDPREMIUMGROUP,
    PODCAST, SERVICES, STAFF, VLOGGER, INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, PROVIDER,
    HAIRSALON, JOKES, PRIVATECHEF, PRIVATEGROUP, STAFF_ACADEMY, BEATMAKER

} from 'src/app/shared/constants/member-constant';
import { SideNavInterface } from '../../../interfaces/side-nav.type';

export const ROUTES: SideNavInterface[] = [
    {
        path: '/settings/profile-settings',
        title: 'myAccountSettings',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'lock',
        allowed_roles: [STAFF, MEMBER, COMPANY, CHARITY, FUNDRAISER, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ONLINECOURSE,
            ECOMMERCE, CUSTOMERSERVICE, CUSTOMERSERVICEADMIN, PROVIDER, HOSTEVENT, RESTAURANT,
            POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, PRIVATEGROUP, BEATMAKER],
        submenu: []
    },
    {
        path: '/admin',
        title: 'Admin Center',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'lock',
        allowed_roles: [STAFF],
        submenu: [
            {
                path: '/admin/article',
                title: 'allArticles',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'crown',
                allowed_roles: [STAFF],
                submenu: []
            },
            {
                path: '/admin/service',
                title: 'allServices',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'crown',
                allowed_roles: [STAFF],
                submenu: []
            },
            {
                path: '/admin/member',
                title: 'memberSettings',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF],
                submenu: []
            },
            {
                path: '/admin/update-companies',
                title: 'Update Companies',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF],
                submenu: []
            },
            {
                path: '/admin/update-charities',
                title: 'Update Charities',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF],
                submenu: []
            },
            {
                path: '/admin/update-fundraisers',
                title: 'Update Fundraisers',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF],
                submenu: []
            },
            {
                path: '/admin/manage-guest-posts/',
                title: 'Manage Guest Posts',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF],
                submenu: []
            },
            {
                path: '/admin/account-verification/',
                title: 'Account Verification',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF],
                submenu: []
            },
            {
                path: '/admin/story-verification/',
                title: 'Story Verification',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF],
                submenu: []
            },
            {
                path: '/admin/company-verification/',
                title: 'Company Verification',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF],
                submenu: []
            },
            {
                path: '/admin/agency-orders/',
                title: 'Agency Orders',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF],
                submenu: []
            },
            {
                path: '/admin/agency-providers/',
                title: 'Agency Providers',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF],
                submenu: []
            },
            {
                path: '/admin/ad-network',
                title: 'Ad network',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF],
                submenu: []
            },
            {
                path: '/admin/politician',
                title: 'allPoliticians',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'crown',
                allowed_roles: [STAFF],
                submenu: []
            },
            {
              path: '/admin/allhairsalons',
              title: 'allhairsalons',
              iconType: 'nzIcon',
              iconTheme: 'outline',
              icon: 'crown',
              allowed_roles: [STAFF],
              submenu: []
            },
            {
                path: '/customer-service/emplist',
                title: 'CustomerServiceEmps',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF],
                submenu: []
            },
            {
                path: '/customer-service/adminlist',
                title: 'CustomerServiceAdmins',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF],
                submenu: []
            },
            {
                path: '/article/abuseList',
                title: 'AbusedArticles',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF], submenu: []
            },
            {
                path: '/event-hosting/abuseList',
                title: 'AbusedHostEvents',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF],
                submenu: []
            },
        ]
    },
    {
      path: '/academy-staff',
      title: 'Academy Center',
      iconType: 'nzIcon',
      iconTheme: 'outline',
      icon: 'lock',
      allowed_roles: [STAFF_ACADEMY, STAFF],
      submenu: [
        {
          path: '/academy-staff/onboarding-program',
          title: 'Onboarding Program',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF_ACADEMY, STAFF],
          submenu: []
        },
        {
          path: '/academy-staff/onboarding-program-six-week',
          title: '6 Week Online Program',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF_ACADEMY, STAFF],
          submenu: []
        },
        {
          path: '/academy-staff/academy-programs',
          title: 'Programs',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF_ACADEMY, STAFF],
          submenu: []
        },
        {
          path: '/academy-staff/newsletter',
          title: 'Newsletter',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF_ACADEMY, STAFF],
          submenu: []
        },
        {
          path: '/academy-staff/membership',
          title: 'Membership',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF_ACADEMY, STAFF],
          submenu: []
        },
        {
          path: '/academy-staff/online-expertise-journey',
          title: 'Online Expertise Journey',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF_ACADEMY, STAFF],
          submenu: []
        },
        {
          path: '/academy-staff/special-events',
          title: 'Special Events',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF_ACADEMY, STAFF],
          submenu: []
        },
        {
          path: '/academy-staff/mastermind',
          title: 'Mastermind',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF_ACADEMY, STAFF],
          submenu: []
        },
        {
          path: '/academy-staff/mentorship',
          title: 'Mentorship',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF_ACADEMY, STAFF],
          submenu: []
        },
        {
          path: '/academy-staff/usertypes-categories',
          title: 'UserTypes & Categories',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF_ACADEMY, STAFF],
          submenu: []
        },
      ],
    },
    {
        path: '/article/articles',
        title: 'myArticles',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'crown',
        allowed_roles: [STAFF, MEMBER, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, CHARITY,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, COMPANY, FUNDRAISER, ECOMMERCE,
            CUSTOMERSERVICE, CUSTOMERSERVICEADMIN, RESTAURANT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, PRIVATEGROUP, JOB],
        submenu: []
    },
    {
        path: '/event-hosting',
        title: 'myArticles',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'crown',
        allowed_roles: [HOSTEVENT, STAFF],
        submenu: []
    },
    {
        path: '/customer-service/admin',
        title: 'Customers Service Admin',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'crown',
        allowed_roles: [CUSTOMERSERVICEADMIN],
        submenu: [],
        notified: false
    },
    {
        path: '/customer-service/emp',
        title: 'Customers Service Emp',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'crown',
        allowed_roles: [CUSTOMERSERVICE],
        submenu: [],
        notified: false
    },
    {
        path: '/customer-service',
        title: 'Customers Service',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'crown',
        allowed_roles: [STAFF, MEMBER, COMPANY, CHARITY, FUNDRAISER, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ONLINECOURSE,
            ECOMMERCE, CUSTOMERSERVICE, CUSTOMERSERVICEADMIN, PROVIDER, HOSTEVENT, RESTAURANT,
            POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, PRIVATEGROUP],
        submenu: [],
        notified: false
    },
    {
        path: '/article/articles',
        title: 'MyStoriesWriting',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'crown',
        allowed_roles: [STAFF, AUTHOR, BLOGGER, COMPANY, CHARITY, FUNDRAISER, ECOMMERCE, SERVICES, POLITICIAN, JOB],
        submenu: []
    },
    {
        path: '/article/articles',
        title: 'MyStoriesAudio',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'crown',
        allowed_roles: [STAFF, PODCAST, COMPANY, CHARITY, FUNDRAISER, ECOMMERCE, SERVICES, POLITICIAN, JOB],
        submenu: []
    },
    {
        path: '/article/articles',
        title: 'MyStoriesVideo',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'crown',
        allowed_roles: [STAFF, VLOGGER, PODCAST, COMPANY, CHARITY, FUNDRAISER, ECOMMERCE,
            SERVICES, POLITICIAN, INFLUENCERMARKETPLACE, RESTAURANT, HAIRSALON, JOKES, PRIVATECHEF, JOB, JOKES, PRIVATECHEF],
        submenu: []
    },
    {
        path: '/event-hosting',
        title: 'MyStoriesVideo',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'crown',
        allowed_roles: [HOSTEVENT],
        submenu: []
    },
    {
        path: '/feed',
        title: 'MyFeed',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'crown',
        allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, CHARITY, FUNDRAISER, ECOMMERCE,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, COMPANY, CUSTOMERSERVICE,
            CUSTOMERSERVICEADMIN, HOSTEVENT, RESTAURANT, POLITICIAN, MEMBER, HAIRSALON, JOKES, PRIVATECHEF, BEATMAKER],
        submenu: [
            {
                path: '/feed/subscribed-feed',
                title: 'Feed',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'notification',
                allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, CHARITY, FUNDRAISER, ECOMMERCE,
                    ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, COMPANY, CUSTOMERSERVICE,
                    CUSTOMERSERVICEADMIN, HOSTEVENT, RESTAURANT, POLITICIAN, MEMBER, HAIRSALON, HAIRSALON, JOKES, PRIVATECHEF],
                submenu: []
            },
            // {
            //     path: '/feed/invite-feed',
            //     title: 'Invite Feed',
            //     iconType: 'nzIcon',
            //     iconTheme: 'outline',
            //     icon: 'notification',
            //     staff_only: false,
            //     submenu: []
            // },
        ]
    },
    {
        path: '/playlist',
        title: 'MyPlaylist',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'notification',
        allowed_roles: [MEMBER, COMPANY, CHARITY, GUESTPOST, PODCAST, VLOGGER, BLOGGER],
        submenu: []
    },
    {
        path: '/service/services',
        title: 'MyServices',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'crown',
        allowed_roles: [STAFF, SERVICES],
        submenu: []
    },
    {
        path: '/infleuncer/list',
        title: 'influencer',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'crown',
        allowed_roles: [STAFF],
        submenu: []
    },
    {
        path: '/my-charity-or-fundraiser',
        title: 'MyCharityorFundraiser',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'notification',
        allowed_roles: [STAFF, AUTHOR, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, CUSTOMERSERVICE,
            CUSTOMERSERVICEADMIN, HOSTEVENT, RESTAURANT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, PRIVATEGROUP],
        submenu: []
    },
    {
        path: '/my-job-posting',
        title: 'myJobPosting',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'crown',
        allowed_roles: [STAFF, JOB, COMPANY, CHARITY, HAIRSALON],
        submenu: []
    },
    {
        path: '/advertising',
        title: 'advertising',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'notification',
        allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
        submenu: []
    },
    {
        path: '/provider-agency',
        title: 'Provider Agency',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'notification',
        allowed_roles: [PROVIDER],
        submenu: []
    },
    {
        path: '/agency',
        title: 'Agency',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'notification',
        allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST],
        submenu: [
            {
                path: '/agency/agency-home',
                title: 'AServices',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'notification',
                allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST],
                submenu: []
            },
            {
                path: '/agency/agency-orders',
                title: 'MyOrders',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'notification',
                allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST],
                submenu: []
            },
            {
                path: '/agency/update-billing',
                title: 'CampBilling',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'notification',
                allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST],
                submenu: []
            },
            // {
            //     path: '/agency/agency-report',
            //     title: 'Report',
            //     iconType: 'nzIcon',
            //     iconTheme: 'outline',
            //     icon: 'notification',
            //     staff_only: false,
            //     submenu: []
            // }
        ]
    },
    {
        path: '/ad-network/sites',
        title: 'AdNetwork',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'notification',
        allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST],
        submenu: [
            {
                path: '/ad-network/report',
                title: 'Report',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'file-text',
                allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST],
                submenu: []
            },
            {
                path: '/ad-network/sites',
                title: 'Sites',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'global',
                allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST],
                submenu: []
            },
            {
                path: '/ad-network/add-new-site',
                title: 'Addnewsites',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'global',
                allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST],
                submenu: []
            },
            // {
            //     path: '/ad-network/payment',
            //     title: 'Payment',
            //     iconType: 'nzIcon',
            //     iconTheme: 'outline',
            //     icon: 'dollar-circle',
            //     staff_only: false,
            //     submenu: []
            // }
        ],
    },
    {
        path: '/services',
        title: 'Booking',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'notification',
        allowed_roles: [STAFF, AUTHOR, ADVISOR, COMPANY,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, JOB, ECOMMERCE, CUSTOMERSERVICE, CUSTOMERSERVICEADMIN,
            POLITICIAN, RESTAURANT, HOSTEVENT, JOKES, PRIVATECHEF, HAIRSALON, BEATMAKER],
        submenu: [
            {
                path: '/services/manage-services',
                title: 'Services',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'global',
                allowed_roles: [STAFF, AUTHOR, ADVISOR, COMPANY, CHARITY, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP,
                    JOB, ECOMMERCE, ONLINECOURSE, INVESTMENT, VACATIONSRENTALS, POLITICIAN],
                submenu: []
            },
            {
                path: '/services/manage-providers',
                title: 'Providers',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'global',
                allowed_roles: [STAFF, AUTHOR, ADVISOR, COMPANY, CHARITY, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP,
                    JOB, ECOMMERCE, ONLINECOURSE, INVESTMENT, VACATIONSRENTALS, POLITICIAN],
                submenu: []
            },
            {
                path: '/services/bookings',
                title: 'Appointments',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'global',
                allowed_roles: [STAFF, AUTHOR, ADVISOR, COMPANY, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, JOB,
                    ECOMMERCE, ONLINECOURSE, INVESTMENT, VACATIONSRENTALS, POLITICIAN],
                submenu: []
            },
            {
                path: '/services/location',
                title: 'Locations',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'global',
                allowed_roles: [STAFF, AUTHOR, ADVISOR, COMPANY, CHARITY, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP,
                    JOB, ECOMMERCE, ONLINECOURSE, INVESTMENT, VACATIONSRENTALS, POLITICIAN],
                submenu: []
            }
        ]
    },
    {
        path: '/campaign',
        title: 'CampManager',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'notification',
        allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE, INVESTMENT,
            VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, BEATMAKER],
        submenu: [
            {
                path: '/campaign/campaign-manager',
                title: 'Campaign',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'notification',
                allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
                    ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
                    INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
                submenu: []
            }, {
                path: '/campaign/update-billing',
                title: 'CampBilling',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'notification',
                allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
                    ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
                    INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
                submenu: []
            },

            {
                path: '/campaign/buy-search-engine',
                title: 'CampSearchEngine',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'notification',
                allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
                    ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
                    INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
                submenu: []
            },
            {
                path: '/campaign/buy-top-contributor',
                title: 'CampSponCon',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'notification',
                allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
                    ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
                    INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
                submenu: []
            }],
    },
    {
        path: '/charity/charity-list',
        title: 'Charities',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'notification',
        allowed_roles: [STAFF, CHARITY],
        submenu: []
    },
    {
        path: '/contributor-post',
        title: 'ContributorPost',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'notification',
        allowed_roles: [STAFF, MEMBER, COMPANY, CHARITY, FUNDRAISER, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ONLINECOURSE, ECOMMERCE,
            CUSTOMERSERVICE, CUSTOMERSERVICEADMIN, PROVIDER, HOSTEVENT, RESTAURANT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, BEATMAKER],
        submenu: []
    },
    {
        path: '/contest',
        title: 'Contest',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'notification',
        allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, BEATMAKER],
        submenu: []
    },
    {
        path: '/donation',
        title: 'donation',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'notification',
        allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, BEATMAKER],
        submenu: []
    },
    {
        path: '/funding',
        title: 'Funding',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'notification',
        allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, MEMBER, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, BEATMAKER],
        submenu: []
    },
    {
        path: '/invitePeopleNetwork',
        title: 'InvitePeopleNetwork',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'notification',
        allowed_roles: [STAFF, MEMBER, BLOGGER, VLOGGER, PODCAST, COMPANY, CHARITY, FUNDRAISER, ADVISOR,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
        submenu: []
    },
    {
        path: '/fundraiser/fundraiser-list',
        title: 'Fundraisers',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'notification',
        allowed_roles: [STAFF, FUNDRAISER, ECOMMERCE],
        submenu: []
    },
    {
      path: '/mtsacademy',
      title: 'MTSAcademy',
      iconType: 'nzIcon',
      iconTheme: 'outline',
      icon: 'notification',
      allowed_roles: [STAFF, AUTHOR, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
        ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
        INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
      submenu: [
        {
          path: '/mtsacademy/onboarding-program',
          title: 'Onboarding Program',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF, AUTHOR, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY,
            FUNDRAISER, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
          submenu: []
        },
        {
          path: '/mtsacademy/six-week-online-program',
          title: '6 Week Online Program',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF, AUTHOR, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY,
            FUNDRAISER, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
          submenu: []
        },
        {
          path: '/mtsacademy/programs',
          title: 'Programs',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF, AUTHOR, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY,
            FUNDRAISER, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
          submenu: []
        },
        {
          path: '/mtsacademy/academy-newsletter',
          title: 'Newsletter',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF, AUTHOR, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY,
            FUNDRAISER, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
          submenu: []
        },
        {
          path: '/mtsacademy/membership',
          title: 'Membership',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF, AUTHOR, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY,
            FUNDRAISER, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
          submenu: []
        },
        {
          path: '/mtsacademy/online-expertise-journey',
          title: 'Online Expertise Journey',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF, AUTHOR, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY,
            FUNDRAISER, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
          submenu: []
        },
        {
          path: '/mtsacademy/special-events',
          title: 'Special Events',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF, AUTHOR, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY,
            FUNDRAISER, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
          submenu: []
        },
        {
          path: '/mtsacademy/mastermind',
          title: 'Mastermind',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF, AUTHOR, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY,
            FUNDRAISER, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
          submenu: []
        },
        {
          path: '/mtsacademy/mentorship',
          title: 'Mentorship',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF, AUTHOR, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY,
            FUNDRAISER, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
          submenu: []
        },
        {
          path: '/mtsacademy/usertypes-categories',
          title: 'UserTypes & Categories',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF, AUTHOR, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY,
            FUNDRAISER, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
          submenu: []
        }
      ]
    },
    {
        path: '/my-orders',
        title: 'MyOrders',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'notification',
        allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, BEATMAKER],
        submenu: []
    },
    {
        path: '/newsletter',
        title: 'Newsletter',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'lock',
        allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, BEATMAKER],
        submenu: []
    },
    {
        path: '/payments',
        title: 'Payments',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'notification',
        allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, BEATMAKER],
        submenu: []
    },
    {
        path: '/company/company-list',
        title: 'CompanyList',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'notification',
        allowed_roles: [STAFF, COMPANY],
        submenu: []
    },
    {
        path: '/politician/politicians',
        title: 'Polititian',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'crown',
        allowed_roles: [STAFF, AUTHOR, ADVISOR,
            ONLINESTORE, PAIDPREMIUMGROUP],
        submenu: []
    },
    {
        path: '/social-posting/post-list',
        title: 'SocialPosting',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'notification',
        allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, BEATMAKER],
        submenu: []
    },
    {
        path: '',
        title: 'E-commerce',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'shopping-cart',
        allowed_roles: [STAFF, ECOMMERCE],
        submenu: [
            {
                path: '/shop/sellproducts/store-list',
                title: 'Stores',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'setting',
                allowed_roles: [STAFF, ECOMMERCE],
                submenu: []
            },
            {
                path: '/shop/sellproducts/product-list',
                title: 'Storeproducts',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'skin',
                allowed_roles: [STAFF, ECOMMERCE],
                submenu: []
            },
            {

                path: '/shop/sellproducts/order-list',
                title: 'Storeorders',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'down-square',
                allowed_roles: [STAFF, ECOMMERCE],
                submenu: []
            },
            {
                path: '/shop/myorders/my-order-list',
                title: 'Myorders',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'skin',
                allowed_roles: [STAFF, ECOMMERCE],
                submenu: []
            },
        ]
    },
    {
        path: '/campaign/buy-sponsored-post',
        title: 'CampSponPost',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'notification',
        allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, MEMBER, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, BEATMAKER],
        submenu: []
    },
    {
        path: '/courses/course-list',
        title: 'CourseList',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'notification',
        allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, MEMBER, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
        submenu: []
    },
    {
        path: '/restaurants/restaurant-list',
        title: 'RestaurantList',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'notification',
        allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, MEMBER, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
        submenu: []
    },
    {
        path: '/store',
        title: 'store',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'notification',
        allowed_roles: [STAFF, ECOMMERCE],
        submenu: []
    },
    {
        path: '/top12',
        title: 'top12',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'notification',
        allowed_roles: [STAFF, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, AUTHOR, COMPANY, CHARITY, FUNDRAISER, AUTHOR, ADVISOR,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ONLINECOURSE, ECOMMERCE,
            CUSTOMERSERVICE, CUSTOMERSERVICEADMIN, PROVIDER, HOSTEVENT, RESTAURANT, POLITICIAN, HAIRSALON,
            JOKES, PRIVATECHEF, PRIVATEGROUP, BEATMAKER],
        submenu: []
    },
    {
        path: '',
        title: 'VideoConference',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'notification',
        allowed_roles: [STAFF, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, AUTHOR, COMPANY, CHARITY, FUNDRAISER, AUTHOR, ADVISOR,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ONLINECOURSE, ECOMMERCE, CUSTOMERSERVICE,
            CUSTOMERSERVICEADMIN, PROVIDER, HOSTEVENT, RESTAURANT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, PRIVATEGROUP, BEATMAKER],
        submenu: [
            {
                path: '/videoConference/subscription',
                title: 'Subscription',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'setting',
                allowed_roles: [STAFF, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, AUTHOR, COMPANY, CHARITY,
                    FUNDRAISER, AUTHOR, ADVISOR, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ONLINECOURSE,
                    ECOMMERCE, CUSTOMERSERVICE, CUSTOMERSERVICEADMIN, PROVIDER, HOSTEVENT, RESTAURANT, POLITICIAN,
                    HAIRSALON, JOKES, PRIVATECHEF, PRIVATEGROUP],
                submenu: []
            },
            {
                path: '/videoConference/create-session',
                title: 'CreateSession',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'setting',
                allowed_roles: [STAFF, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, AUTHOR, COMPANY, CHARITY, FUNDRAISER, AUTHOR, ADVISOR,
                    ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ONLINECOURSE,
                    ECOMMERCE, CUSTOMERSERVICE, CUSTOMERSERVICEADMIN, PROVIDER, HOSTEVENT, RESTAURANT, POLITICIAN,
                    HAIRSALON, JOKES, PRIVATECHEF, PRIVATEGROUP],
                submenu: []
            },
            {
                path: '/videoConference/session-list',
                title: 'AllSessions',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'skin',
                allowed_roles: [STAFF, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, AUTHOR, COMPANY, CHARITY, FUNDRAISER, AUTHOR, ADVISOR,
                    ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ONLINECOURSE, ECOMMERCE,
                    CUSTOMERSERVICE, CUSTOMERSERVICEADMIN, PROVIDER, HOSTEVENT, RESTAURANT, POLITICIAN, HAIRSALON,
                    JOKES, PRIVATECHEF, PRIVATEGROUP],
                submenu: []
            }
        ]
    },
    {
        path: '',
        title: 'hairSalon',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'notification',
        allowed_roles: [STAFF, HAIRSALON],
        submenu: [
            {
                path: '/hair-salon/hair-salon-list',
                title: 'MyHairSalon',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'skin',
                allowed_roles: [STAFF, HAIRSALON],
                submenu: []
            },
            {
                path: '/hair-salon/create-hair-salon',
                title: 'CreateHairSalon',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'setting',
                allowed_roles: [STAFF, HAIRSALON],
                submenu: []
            }
        ]
    },
    {
        path: '/myMeetings',
        title: 'MyMeetings',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'notification',
        allowed_roles: [STAFF, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, AUTHOR, COMPANY, CHARITY, FUNDRAISER, AUTHOR, ADVISOR,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ONLINECOURSE, ECOMMERCE,
            CUSTOMERSERVICE, CUSTOMERSERVICEADMIN, PROVIDER, HOSTEVENT, RESTAURANT, POLITICIAN, HAIRSALON,
            JOKES, PRIVATECHEF, PRIVATEGROUP],
        submenu: []
    },
    {
        path: '',
        title: 'Beatmaker',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'lock',
        allowed_roles: [STAFF, BEATMAKER],
        submenu: [
            {
                path: '/beat-maker/beat-list',
                title: 'My Beats',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'unordered-list',
                allowed_roles: [STAFF, BEATMAKER],
                submenu: []
            },

            {
                path: '/beat-maker/beat-details',
                title: 'Create Beats',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'plus',
                allowed_roles: [STAFF, BEATMAKER],
                submenu: []
            }
        ]
    }
];

export const SERVICE_MENU: SideNavInterface[] = [
    {
        path: '/settings/profile-settings',
        title: 'myAccountSettings',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'lock',
        allowed_roles: [SERVICES],
        submenu: []
    },
    {
        path: '/article',
        title: 'myArticles',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'lock',
        allowed_roles: [STAFF, MEMBER, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, CHARITY,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, COMPANY, FUNDRAISER, ECOMMERCE, CUSTOMERSERVICE,
            CUSTOMERSERVICEADMIN, RESTAURANT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, PRIVATEGROUP, JOB],
        submenu: [
            {
                path: '/article/articles/text',
                title: 'MyStoriesWriting',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF, AUTHOR, BLOGGER, COMPANY, CHARITY, FUNDRAISER, ECOMMERCE, SERVICES, POLITICIAN, JOB],
                submenu: []
            },
            {
                path: '/article/articles/video',
                title: 'MyStoriesVideo',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF, VLOGGER, PODCAST, COMPANY, CHARITY, FUNDRAISER, ECOMMERCE,
                    SERVICES, POLITICIAN, INFLUENCERMARKETPLACE, RESTAURANT, HAIRSALON, JOKES, PRIVATECHEF, JOB, JOKES, PRIVATECHEF],
                submenu: []
            },
            {
                path: '/article/articles/audio',
                title: 'MyStoriesAudio',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF, PODCAST, COMPANY, CHARITY, FUNDRAISER, ECOMMERCE, SERVICES, POLITICIAN, JOB],
                submenu: []
            }
        ]
    },
    {
        path: '/feed',
        title: 'MyFeed',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'lock',
        allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, CHARITY, FUNDRAISER, ECOMMERCE,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, COMPANY, CUSTOMERSERVICE,
            CUSTOMERSERVICEADMIN, HOSTEVENT, RESTAURANT, POLITICIAN, MEMBER, HAIRSALON, JOKES, PRIVATECHEF],
        submenu: [
            {
                path: '/feed/subscribed-feed',
                title: 'Feed',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, CHARITY, FUNDRAISER, , ECOMMERCE,
                    ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, COMPANY,
                    CUSTOMERSERVICE, CUSTOMERSERVICEADMIN, HOSTEVENT, RESTAURANT, POLITICIAN, MEMBER, HAIRSALON,
                    HAIRSALON, JOKES, PRIVATECHEF],
                submenu: []
            },
        ]
    },
    {
        path: '/service/services',
        title: 'MyServices',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'lock',
        allowed_roles: [STAFF, SERVICES],
        submenu: []
    },
    {
        path: '/playlist',
        title: 'MyPlaylist',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'lock',
        allowed_roles: [MEMBER, COMPANY, CHARITY, GUESTPOST, PODCAST, VLOGGER, BLOGGER, SERVICES],
        submenu: []
    },
    {
        path: '/my-charity-or-fundraiser',
        title: 'MyCharityorFundraiser',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'lock',
        allowed_roles: [STAFF, AUTHOR, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, CUSTOMERSERVICE,
            CUSTOMERSERVICEADMIN, HOSTEVENT, RESTAURANT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, PRIVATEGROUP],
        submenu: []
    },
    {
        path: '/advertising',
        title: 'advertising',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'lock',
        allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE, INVESTMENT,
            VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
        submenu: []
    },
    {
        path: '/services',
        title: 'Booking',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'lock',
        allowed_roles: [STAFF, AUTHOR, ADVISOR, COMPANY,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, JOB, ECOMMERCE, CUSTOMERSERVICE, CUSTOMERSERVICEADMIN,
            POLITICIAN, RESTAURANT, HOSTEVENT, JOKES, PRIVATECHEF, HAIRSALON],
        submenu: [
            {
                path: '/services/manage-services',
                title: 'Services',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF, AUTHOR, ADVISOR, COMPANY, CHARITY, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP,
                    JOB, ECOMMERCE, ONLINECOURSE, INVESTMENT, VACATIONSRENTALS, POLITICIAN],
                submenu: []
            },
            {
                path: '/services/manage-providers',
                title: 'Providers',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF, AUTHOR, ADVISOR, COMPANY, CHARITY, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, JOB,
                    ECOMMERCE, ONLINECOURSE, INVESTMENT, VACATIONSRENTALS, POLITICIAN],
                submenu: []
            },
            {
                path: '/services/bookings',
                title: 'Appointments',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF, AUTHOR, ADVISOR, COMPANY, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, JOB,
                    ECOMMERCE, ONLINECOURSE, INVESTMENT, VACATIONSRENTALS, POLITICIAN],
                submenu: []
            },
            {
                path: '/services/location',
                title: 'Locations',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF, AUTHOR, ADVISOR, COMPANY, CHARITY, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, JOB,
                    ECOMMERCE, ONLINECOURSE, INVESTMENT, VACATIONSRENTALS, POLITICIAN],
                submenu: []
            }
        ]
    },
    {
        path: '/campaign',
        title: 'CampManager',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'lock',
        allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE, INVESTMENT, VACATIONSRENTALS,
            RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, BEATMAKER],
        submenu: [
            {
                path: '/campaign/campaign-manager',
                title: 'Campaign',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
                    ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE,
                    ONLINECOURSE, INVESTMENT, VACATIONSRENTALS,
                    RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
                submenu: []
            }, {
                path: '/campaign/update-billing',
                title: 'CampBilling',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
                    ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE,
                    ONLINECOURSE, INVESTMENT, VACATIONSRENTALS,
                    RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
                submenu: []
            },

            {
                path: '/campaign/buy-search-engine',
                title: 'CampSearchEngine',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
                    ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE,
                    ONLINECOURSE, INVESTMENT, VACATIONSRENTALS,
                    RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
                submenu: []
            },
            {
                path: '/campaign/buy-top-contributor',
                title: 'CampSponCon',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
                    ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE,
                    ONLINECOURSE, INVESTMENT, VACATIONSRENTALS,
                    RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
                submenu: []
            }],
    },
    {
        path: '/contest',
        title: 'Contest',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'lock',
        allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE, INVESTMENT, VACATIONSRENTALS,
            RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, BEATMAKER],
        submenu: []
    },
    {
        path: '/contributor-post',
        title: 'ContributorPost',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'lock',
        allowed_roles: [STAFF, MEMBER, COMPANY, CHARITY, FUNDRAISER, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ONLINECOURSE,
            ECOMMERCE, CUSTOMERSERVICE, CUSTOMERSERVICEADMIN,
            PROVIDER, HOSTEVENT, RESTAURANT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, BEATMAKER],
        submenu: []
    },
    {
        path: '/customer-service',
        title: 'CustomerService',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'lock',
        allowed_roles: [STAFF, MEMBER, COMPANY, CHARITY, FUNDRAISER, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ONLINECOURSE, ECOMMERCE,
            CUSTOMERSERVICE, CUSTOMERSERVICEADMIN,
            PROVIDER, HOSTEVENT, RESTAURANT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, PRIVATEGROUP],
        submenu: [],
        notified: false
    },
    {
        path: '/donation',
        title: 'donation',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'lock',
        allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE, INVESTMENT, VACATIONSRENTALS,
            RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
        submenu: []
    },
    {
        path: '/funding',
        title: 'Funding',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'lock',
        allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, MEMBER,
            ONLINECOURSE, INVESTMENT, VACATIONSRENTALS,
            RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
        submenu: []
    },
    {
        path: '/invitePeopleNetwork',
        title: 'InvitePeopleNetwork',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'lock',
        allowed_roles: [STAFF, MEMBER, BLOGGER, VLOGGER, PODCAST, COMPANY, CHARITY, FUNDRAISER,
            ADVISOR, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP,
            INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE, INVESTMENT, VACATIONSRENTALS,
            RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
        submenu: []
    },
    {
      path: '/mtsacademy',
      title: 'MTSAcademy',
      iconType: 'nzIcon',
      iconTheme: 'outline',
      icon: 'notification',
      allowed_roles: [STAFF, AUTHOR, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
        ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
        INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
      submenu: [
        {
          path: '/mtsacademy/onboarding-program',
          title: 'Onboarding Program',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF, AUTHOR, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY,
            FUNDRAISER, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
          submenu: []
        },
        {
          path: '/mtsacademy/six-week-online-program',
          title: '6 Week Online Program',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF, AUTHOR, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY,
            FUNDRAISER, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
          submenu: []
        },
        {
          path: '/mtsacademy/programs',
          title: 'Programs',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF, AUTHOR, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY,
            FUNDRAISER, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
          submenu: []
        },
        {
          path: '/mtsacademy/academy-newsletter',
          title: 'Newsletter',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF, AUTHOR, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY,
            FUNDRAISER, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
          submenu: []
        },
        {
          path: '/mtsacademy/membership',
          title: 'Membership',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF, AUTHOR, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY,
            FUNDRAISER, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
          submenu: []
        },
        {
          path: '/mtsacademy/online-expertise-journey',
          title: 'Online Expertise Journey',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF, AUTHOR, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY,
            FUNDRAISER, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
          submenu: []
        },
        {
          path: '/mtsacademy/special-events',
          title: 'Special Events',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF, AUTHOR, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY,
            FUNDRAISER, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
          submenu: []
        },
        {
          path: '/mtsacademy/mastermind',
          title: 'Mastermind',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF, AUTHOR, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY,
            FUNDRAISER, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
          submenu: []
        },
        {
          path: '/mtsacademy/mentorship',
          title: 'Mentorship',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF, AUTHOR, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY,
            FUNDRAISER, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
          submenu: []
        },
        {
          path: '/mtsacademy/usertypes-categories',
          title: 'UserTypes & Categories',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'crown',
          allowed_roles: [STAFF, AUTHOR, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY,
            FUNDRAISER, ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE,
            INVESTMENT, VACATIONSRENTALS, RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
          submenu: []
        }
      ]
    },
    {
        path: '/my-orders',
        title: 'MyOrders',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'lock',
        allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE,
            ONLINECOURSE, INVESTMENT, VACATIONSRENTALS, RESTAURANT,
            HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, BEATMAKER],
        submenu: []
    },
    {
        path: '/newsletter',
        title: 'Newsletter',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'lock',
        allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE, INVESTMENT, VACATIONSRENTALS,
            RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, BEATMAKER],
        submenu: []
    },
    {
        path: '/payments',
        title: 'Payments',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'lock',
        allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE, INVESTMENT, VACATIONSRENTALS,
            RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
        submenu: []
    },
    {
        path: '/social-posting/post-list',
        title: 'SocialPosting',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'lock',
        allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE, INVESTMENT, VACATIONSRENTALS,
            RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF],
        submenu: []
    },
    {
        path: '/sponsor-post',
        title: 'SponsoredPost',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'lock',
        allowed_roles: [STAFF, AUTHOR, BLOGGER, VLOGGER, PODCAST, GUESTPOST, ADVISOR, COMPANY, CHARITY, FUNDRAISER,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ECOMMERCE, ONLINECOURSE, INVESTMENT, VACATIONSRENTALS,
            RESTAURANT, HOSTEVENT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, BEATMAKER],
        submenu: []
    },
    {
        path: '/top12',
        title: 'top12',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'lock',
        allowed_roles: [STAFF, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, AUTHOR, COMPANY, CHARITY, FUNDRAISER, AUTHOR, ADVISOR,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ONLINECOURSE, ECOMMERCE,
            CUSTOMERSERVICE, CUSTOMERSERVICEADMIN,
            PROVIDER, HOSTEVENT, RESTAURANT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, PRIVATEGROUP, BEATMAKER],
        submenu: []
    },
    {
        path: '',
        title: 'VideoConference',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'lock',
        allowed_roles: [STAFF, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, AUTHOR, COMPANY, CHARITY, FUNDRAISER, AUTHOR, ADVISOR,
            ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ONLINECOURSE, ECOMMERCE,
            CUSTOMERSERVICE, CUSTOMERSERVICEADMIN,
            PROVIDER, HOSTEVENT, RESTAURANT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, PRIVATEGROUP, BEATMAKER],
        submenu: [
            {
                path: '/videoConference/subscription',
                title: 'Subscription',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, AUTHOR, COMPANY, CHARITY, FUNDRAISER, AUTHOR, ADVISOR,
                    ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ONLINECOURSE,
                    ECOMMERCE, CUSTOMERSERVICE, CUSTOMERSERVICEADMIN,
                    PROVIDER, HOSTEVENT, RESTAURANT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, PRIVATEGROUP],
                submenu: []
            },
            {
                path: '/videoConference/create-session',
                title: 'CreateSession',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, AUTHOR, COMPANY, CHARITY, FUNDRAISER, AUTHOR, ADVISOR,
                    ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ONLINECOURSE,
                    ECOMMERCE, CUSTOMERSERVICE, CUSTOMERSERVICEADMIN,
                    PROVIDER, HOSTEVENT, RESTAURANT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, PRIVATEGROUP],
                submenu: []
            },
            {
                path: '/videoConference/session-list',
                title: 'AllSessions',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'lock',
                allowed_roles: [STAFF, MEMBER, BLOGGER, VLOGGER, PODCAST, GUESTPOST, AUTHOR, COMPANY, CHARITY, FUNDRAISER, AUTHOR, ADVISOR,
                    ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB, ONLINECOURSE,
                    ECOMMERCE, CUSTOMERSERVICE, CUSTOMERSERVICEADMIN,
                    PROVIDER, HOSTEVENT, RESTAURANT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, PRIVATEGROUP],
                submenu: []
            },
            {
                path: '/app/private-channel/private-channel-list',
                title: 'PrivateChannelsList',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'notification',
                allowed_roles: [
                    STAFF, MEMBER, BLOGGER, VLOGGER, PODCAST,
                    GUESTPOST, AUTHOR, COMPANY, CHARITY, FUNDRAISER, AUTHOR, ADVISOR,
                    ONLINESTORE, SERVICES, PAIDPREMIUMGROUP, INFLUENCERMARKETPLACE, JOB,
                    ONLINECOURSE, ECOMMERCE, CUSTOMERSERVICE, CUSTOMERSERVICEADMIN, PROVIDER,
                    HOSTEVENT, RESTAURANT, POLITICIAN, HAIRSALON, JOKES, PRIVATECHEF, PRIVATEGROUP
                ],
                submenu: []
            },
        ]
    },
    {
        path: '',
        title: 'Beatmaker',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'lock',
        allowed_roles: [STAFF, BEATMAKER],
        submenu: [
            {
                path: '/beat-maker/beats-list',
                title: 'My Beats',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'unordered-list',
                allowed_roles: [STAFF, BEATMAKER],
                submenu: []
            },

            {
                path: '/beat-maker/create-beats',
                title: 'Create Beats',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'plus',
                allowed_roles: [STAFF, BEATMAKER],
                submenu: []
            }
        ]
    },

];
