import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IPayPalConfig, ICreateOrderRequest  } from 'ngx-paypal';
import { Router } from '@angular/router';
import { BackofficeArticleService } from 'src/app/backoffice/shared/services/backoffice-article.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AnalyticsService } from '../../services/analytics/analytics.service';

@Component({
  selector: 'app-paypal-button',
  templateUrl: './paypal-button.component.html',
  styleUrls: ['./paypal-button.component.css']
})

export class PaypalButtonComponent implements OnInit {

  @Input() amount: number;
  @Input() articleId: string;

  private currencyCode = 'USD';
  public payPalConfig?: IPayPalConfig;

  constructor (
    private cdk: ChangeDetectorRef,
    private router: Router,
    private articleService: BackofficeArticleService,
    private message: NzMessageService,
    private analyticsService: AnalyticsService,
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.initConfig();
      this.cdk.detectChanges();
    }, 1500);
  }

  private initConfig(): void {
    this.payPalConfig = {
      currency: this.currencyCode,
      clientId: environment.paypal.clientId,
      createOrderOnClient: (data) => < ICreateOrderRequest > {
          intent: 'CAPTURE',
          purchase_units: [{
              amount: {
                  currency_code: this.currencyCode,
                  value: `${this.amount}`,
                  breakdown: {
                      item_total: {
                          currency_code: this.currencyCode,
                          value: `${this.amount}`
                      }
                  }
              },
              items: [{
                  name: this.articleId,
                  quantity: '1',
                  category: 'DIGITAL_GOODS',
                  unit_amount: {
                      currency_code: this.currencyCode,
                      value: `${this.amount}`,
                  },
              }]
          }]
      },
      advanced: {
          commit: 'true'
      },
      style: {
          label: 'paypal',
          layout: 'vertical'
      },
      onClientAuthorization: (data) => {
        this.message.success(`Payment of $${this.amount} was successfull`);
        const payload = {
          is_paid_guest_post: true,
          payment_source: 'paypal',
          payment_details: data,
          paid_amount: this.amount,
        };
        this.articleService.updateArticleImage(this.articleId, payload).then(() => {
        }).catch(err => {
          this.articleService.updateArticle(this.articleId, payload);
        });

        this.analyticsService.logEvent('guest_post_success', {
          article_id: this.articleId,
          paid_amount: this.amount,
          payment_source: 'paypal',
          response: JSON.stringify(data),
        });
      },
      onCancel: (data, actions) => {
        this.message.info(`Payment of $${this.amount} was cancelled`);
      },
      onError: err => {
        this.message.error(`Payment of $${this.amount} failed`);

        this.analyticsService.logEvent('guest_post_error', {
          article_id: this.articleId,
          error: err?.message,
          paid_amount: this.amount,
          payment_source: 'paypal',
        });
      },
    };
  }

}
