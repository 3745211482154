import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  ActivatedRoute,
} from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { AuthService } from '../services/authentication.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private afAuth: AngularFireAuth,
    private authService: AuthService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.authService.showGuardLoader = true;
    return new Observable<boolean>((observer) => {
      this.afAuth.authState.subscribe((userData) => {
        this.authService.getCustomClaimData().then((role) => {
          if (
            (userData && userData.emailVerified && !route.data.roles) ||
            (route.data.roles && route.data.roles.indexOf(role) > -1)
          ) {
            this.authService.showGuardLoader = false;
            if (route.queryParams?.token) {
              const redirectUrlPath = window.location.pathname,
                queryParams = route.queryParams.queryParams
                  ? JSON.parse(route.queryParams.queryParams)
                  : {};
              observer.next(true);
              this.router.navigate([redirectUrlPath], {
                queryParams: queryParams,
              });
            } else {
              observer.next(true);
            }
          } else if (route.queryParams?.token) {
            const redirectUrlPath = window.location.pathname;
            const token = route.queryParams.token;
            const queryParams = route.queryParams.queryParams
              ? JSON.parse(route.queryParams.queryParams)
              : {};
            this.authService
              .signInWithToken(token)
              .then((data) => {
                this.authService.showGuardLoader = false;
                this.router.navigate([redirectUrlPath], {
                  queryParams: queryParams,
                });
              })
              .catch((err) => {
                observer.next(false);
                window.open(environment.publicSectionUrl, '_self');
                this.authService.showGuardLoader = false;
              });
          } else {
            this.authService.showGuardLoader = false;
            observer.next(false);
            if (userData.isAnonymous) {
              window.open(environment.publicSectionUrl, '_self');
            }
          }
          observer.complete();
        });
      });
    });
  }
}

/*
*https://console.mytrendingstories.dev/app

*/
