import { Component, OnInit } from '@angular/core';
import { AuthService } from './shared/services/authentication.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    constructor(
        public authService: AuthService
    ) { }

    ngOnInit() {}
}
