export const USER_TYPE = [
    'blogger',
    'vlogger',
    'podcaster',
    'guest-post',
    'company',
    'charity',
    'fundraiser',
    'ecommerce',
    'member',
    'advisor',
    'online-course',
    'services',
    'job',
    'host-event',
    'influencer-marketing',
    'paid-premium-group',
    'restaurants',
    'politician',
    'investment',
    'vacations-rentals'
];

export const LANGS = ['en', 'fr', 'es'];
export const CUSTOMER_SERVICE_USER_TYPE = 'customer-service';
export const CUSTOMER_SERVICE_ADMIN_USER_TYPE = 'customer-service-admin';
