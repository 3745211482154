import { CloudinaryModule, CloudinaryConfiguration } from '@cloudinary/angular-5.x';
import { Cloudinary } from 'cloudinary-core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ImgSizePipe } from './pipes/img-size.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { ShopProductAddReviewComponent } from './component/shop-product-add-review/shop-product-add-review.component';
import { StripTagsPipe } from './pipes/striptags.pipe';
import { ThemeConstantService } from './services/theme-constant.service';
import { AdDirective } from './directives/ad/ad.directive';
import { AnalyticsService } from './services/analytics/analytics.service';
import { SeoService } from './services/seo/seo.service';
import { LinkSocialAccountComponent } from './component/link-social-account/link-social-account.component';
import { PaypalButtonComponent } from './component/paypal-button/paypal-button.component';
import { NgxPayPalModule } from 'ngx-paypal';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgZorroAntdModule,
        PerfectScrollbarModule,
        SearchPipe,
        LinkSocialAccountComponent,
        ShopProductAddReviewComponent,
        AdDirective,
        PaypalButtonComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        NgZorroAntdModule,
        PerfectScrollbarModule,
        ReactiveFormsModule,
        NgxPayPalModule,
        CloudinaryModule.forRoot({ Cloudinary }, { cloud_name: 'mytrendingstories' } as CloudinaryConfiguration),
        TranslateModule.forChild({
            useDefaultLang: true,
            isolate: false,
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        })
    ],
    declarations: [
        SearchPipe,
        StripTagsPipe,
        ImgSizePipe,
        LinkSocialAccountComponent,
        ShopProductAddReviewComponent,
        AdDirective,
        PaypalButtonComponent
    ],
    providers: [
        ThemeConstantService,
        AnalyticsService,
        SeoService,
    ]
})

export class SharedModule { }
