<perfect-scrollbar class="side-nav" appSideNav>
  <ul
    class="ant-menu ant-menu-root ant-menu-inline side-nav-menu side-nav-list"
    [ngClass]="{
      'ant-menu-inline-collapsed': isFolded,
      'ant-menu-dark': isSideNavDark
    }"
  >
    <ng-container *ngFor="let item of menuItems">
      <li
        [ngClass]="
          item.submenu.length > 0
            ? 'ant-menu-submenu ant-menu-submenu-inline'
            : 'ant-menu-item'
        "
        *ngIf="item.allowed_roles && isAccessAllowed(item.allowed_roles)"
        [routerLinkActive]="
          item.submenu.length > 0
            ? 'ant-menu-submenu-open'
            : 'ant-menu-item-selected'
        "
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <a
          href="javascript:void(0);"
          class="ant-menu-submenu-title"
          *ngIf="item.submenu.length > 0"
        >
          <i
            *ngIf="item.iconType == 'nzIcon'"
            nz-icon
            [nzType]="item.icon"
            [theme]="item.iconTheme"
          ></i>
          <i
            *ngIf="item.iconType == 'fontawesome'"
            class="m-r-10"
            [ngClass]="[item.iconTheme, item.icon]"
          ></i>
          <span>{{ item.title | translate }}</span>
          <i class="ant-menu-submenu-arrow"></i>
        </a>
        <a [routerLink]="item.path" *ngIf="!item.submenu.length > 0">
          <i
            *ngIf="item.iconType == 'nzIcon'"
            nz-icon
            [nzType]="item.icon"
            [theme]="item.iconTheme"
          ></i>
          <i
            *ngIf="item.iconType == 'fontawesome'"
            class="m-r-10"
            [ngClass]="[item.iconTheme, item.icon]"
          ></i>
          <span>{{ item.title | translate }}</span>
          &nbsp;
          <span
            *ngIf="item.notified == true"
            class="translate-middle bg-success border-dark border rounded-circle"
          >
            <span class="visually-hidden text-right"> &nbsp;&nbsp;&nbsp;</span>
          </span>
        </a>

        <!-- Second Level -->
        <ul
          class="ant-menu ant-menu-inline ant-menu-sub dropdown-menu side-nav-list"
          *ngIf="item.submenu.length > 0"
        >
          <li
            [ngClass]="
              subItem.submenu.length > 0
                ? 'ant-menu-submenu ant-menu-submenu-inline'
                : 'ant-menu-item'
            "
            *ngFor="let subItem of item.submenu"
            [routerLinkActive]="
              subItem.submenu.length > 0
                ? 'ant-menu-submenu-open'
                : 'ant-menu-item-selected'
            "
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a
              href="javascript:void(0);"
              class="ant-menu-submenu-title p-l-50"
              *ngIf="subItem.submenu.length > 0"
            >
              <i
                *ngIf="subItem.iconType == 'nzIcon'"
                nz-icon
                [nzType]="subItem.icon"
                [theme]="subItem.iconTheme"
              ></i>
              <i
                *ngIf="subItem.iconType == 'fontawesome'"
                class="m-r-10"
                [ngClass]="[subItem.iconTheme, subItem.icon]"
              ></i>
              <span>{{ subItem.title | translate }}</span>
              <i class="ant-menu-submenu-arrow"></i>
            </a>
            <a
              [routerLink]="subItem.path"
              class="p-l-30"
              *ngIf="!subItem.submenu.length > 0"
            >
              <i
                *ngIf="subItem.iconType == 'nzIcon'"
                nz-icon
                [nzType]="subItem.icon"
                [theme]="subItem.iconTheme"
              ></i>
              <i
                *ngIf="subItem.iconType == 'fontawesome'"
                class="m-r-10"
                [ngClass]="[subItem.iconTheme, subItem.icon]"
              ></i>
              <span>{{ subItem.title | translate }}</span>
            </a>

            <!-- Third Level -->
            <ul
              class="ant-menu ant-menu-inline ant-menu-sub dropdown-menu"
              *ngIf="subItem.submenu.length > 0"
            >
              <li
                [ngClass]="
                  subItem.submenu.length > 0
                    ? 'ant-menu-submenu ant-menu-submenu-inline'
                    : 'ant-menu-item'
                "
                *ngFor="let subItem of subItem.submenu"
                routerLinkActive="ant-menu-item-selected"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <a
                  href="javascript:void(0);"
                  class="ant-menu-submenu-title p-l-50"
                  *ngIf="subItem.submenu.length > 0"
                >
                  <i
                    *ngIf="subItem.iconType == 'nzIcon'"
                    nz-icon
                    [nzType]="subItem.icon"
                    [theme]="subItem.iconTheme"
                  ></i>
                  <i
                    *ngIf="subItem.iconType == 'fontawesome'"
                    class="m-r-10"
                    [ngClass]="[subItem.iconTheme, subItem.icon]"
                  ></i>
                  <span>{{ subItem.title | translate }}</span>
                  <i class="ant-menu-submenu-arrow"></i>
                </a>
                <a
                  [routerLink]="subItem.path"
                  class="p-l-50"
                  *ngIf="!subItem.submenu.length > 0"
                >
                  <i
                    *ngIf="subItem.iconType == 'nzIcon'"
                    nz-icon
                    [nzType]="subItem.icon"
                    [theme]="subItem.iconTheme"
                  ></i>
                  <i
                    *ngIf="subItem.iconType == 'fontawesome'"
                    class="m-r-10"
                    [ngClass]="[subItem.iconTheme, subItem.icon]"
                  ></i>
                  <span>{{ subItem.title | translate }}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ng-container>
  </ul>
</perfect-scrollbar>
