<footer class="footer" style="background-color: unset;">
    <div class="footer-content justify-content-between">
        <p class="m-b-0">Copyright © {{currentYear}} My Treanding Stories. All rights reserved.</p>
        <span>
            <a href="https://mytrendingstories.com/terms" target="_blank" class="text-gray m-r-15">Term &amp;
                Conditions</a>
            <a href="https://mytrendingstories.com/contact" target="_blank" class="text-gray m-r-15">Contact</a>
            <a href="https://mytrendingstories.com/faq" target="_blank" class="text-gray">FAQ</a>
        </span>
    </div>
</footer>