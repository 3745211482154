import { Component, OnInit } from '@angular/core';
import { ROUTES, SERVICE_MENU } from './side-nav-routes.config';
import { ThemeConstantService } from '../../../services/theme-constant.service';
import { TranslateService } from '@ngx-translate/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../../../services/authentication.service';
import { CUSTOMERSERVICE, CUSTOMERSERVICEADMIN, SERVICES, STAFF } from '../../../constants/member-constant';
import { CustomersServiceService } from '../../../../backoffice/shared/services/customers-service.service';

@Component({
    selector: 'app-sidenav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.scss']
})

export class SideNavComponent implements OnInit {

    public menuItems: any[];
    isFolded = false;
    isSideNavDark: boolean;
    isStaffAccount: boolean;
    user_type: any;
    userId;
    newTickets: Boolean = false;
    callIndex = 0;
    customerServiceAdminType;

    constructor(private themeService: ThemeConstantService,
        public translate: TranslateService,
        public afAuth: AngularFireAuth,
        public authService: AuthService,
        public customersServiceService: CustomersServiceService,
    ) { }

    ngOnInit(): void {
        this.menuItems = ROUTES.filter(menuItem => menuItem);

        this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
        this.afAuth.authState.subscribe(() => {
            this.authService.getCustomClaimData().then((role) => {
                if (role === STAFF) {
                    this.isStaffAccount = true;
                } else {
                    this.authService.getLoggedInUserDetails().then((data) => {
                        this.user_type = data.user_type ? data.user_type : data.type;
                        this.userId = data.id;
                        this.customerServiceAdminType = data.customerServiceAdminType;
                        this.checkNewTickets();
                        if (this.user_type === CUSTOMERSERVICE) {
                            this.checkNewEmpTickets();
                        }

                        if (this.user_type === CUSTOMERSERVICEADMIN) {
                            this.checkNewAdminTickets();
                        }

                        if (this.user_type === SERVICES) {
                            this.menuItems = SERVICE_MENU.map(m => m);
                        }
                    });
                }
            });
        });
    }

    isAccessAllowed = (allowed_roles: Array<string>) => {
        return this.isStaffAccount ? allowed_roles.includes(STAFF) : allowed_roles.includes(this.user_type);
    }

    checkNewTickets() {
        this.customersServiceService.getTickets('customer_id', this.userId).subscribe((res: any) => {
            const tickets = res;
            this.callIndex += 1;
            this.newTickets = false;
            for (let i = 0; i < tickets.length; i++) {
                if (tickets[i].seen === false) {
                    this.newTickets = true;
                    break;
                }
            }

            const index = this.menuItems.findIndex(obj => obj.path === '/customer-service');

            if (index !== -1) {
                this.menuItems[index].notified = this.newTickets;
            }
        });
    }

    checkNewEmpTickets() {
        this.customersServiceService.getTickets('assignedTo_id', this.userId).subscribe((res: any) => {
            const tickets = res;
            this.callIndex += 1;
            let newTickets = false;
            for (let i = 0; i < tickets.length; i++) {
                if (tickets[i].providerSeen === false) {
                    newTickets = true;
                    break;
                }
            }

            const index = this.menuItems.findIndex(obj => obj.path === '/customer-service/emp');

            if (index !== -1) {
                this.menuItems[index].notified = newTickets;
            }
        });
    }

    checkNewAdminTickets() {
        this.customersServiceService.getTickets('type', this.customerServiceAdminType).subscribe((res: any) => {
            const tickets = res;
            this.callIndex += 1;
            let newTickets = false;
            for (let i = 0; i < tickets.length; i++) {
                if (tickets[i].adminSeen === false) {
                    newTickets = true;
                    break;
                }
            }

            const index = this.menuItems.findIndex(obj => obj.path === '/customer-service/admin');

            if (index !== -1) {
                this.menuItems[index].notified = newTickets;
            }
        });
    }
}
