<div class="row">

  <div class="col-xs-12 col-md-6 col-lg-4">
    <div class="account-div" [ngStyle]="{'color': social.facebook.color}">
      <img [src]='social.facebook.logo'>
      Facebook
      <button 
        [ngStyle]="{ 'color': social.facebook.color, 'border': '1px solid', 'border-color': social.facebook.color}"
        *ngIf="!socialPostingService?.socialPlatformsStatus?.facebook" 
        class="m-l-30" nz-button nzSize="small" nzType="default" 
        [nzLoading]="fbloading"
        (click)="linkFacebook()"
      >
        {{ "Link" | translate }}
      </button>
      <button 
        [ngStyle]="{ 'color': social.facebook.color, 'border': '1px solid', 'border-color': social.facebook.color}"
        *ngIf="socialPostingService?.socialPlatformsStatus?.facebook" 
        class="m-l-30" nz-button nzSize="small" nzType="default" 
        [nzLoading]="fbloading"
        (click)="unlinkFacebook()"
      >
        {{ "Unlink" | translate }}
      </button>
    </div>
  </div>

  <div class="col-xs-12 col-md-6 col-lg-4">
    <div class="account-div" [ngStyle]="{'color': social.linkedin.color}">
      <img [src]="social.linkedin.logo">
      Linkedin
      <button 
        [ngStyle]="{ 'color': social.linkedin.color, 'border': '1px solid', 'border-color': social.linkedin.color}"
        class="m-l-30" nz-button nzSize="small" nzType="default" 
        [nzLoading]="linkLoading" 
        (click)="linkLinkedin()"
        *ngIf="!socialPostingService?.socialPlatformsStatus?.linkedin"
      >
        {{ "Link" | translate }}
      </button>
      <button 
        [ngStyle]="{ 'color': social.linkedin.color, 'border': '1px solid', 'border-color': social.linkedin.color}"
        class="m-l-30" 
        nz-button nzSize="small" nzType="default" 
        [nzLoading]="linkLoading" 
        (click)="unlinkLinkedin()"
        *ngIf="socialPostingService?.socialPlatformsStatus?.linkedin"
      >
        {{ "Unlink" | translate }}
      </button>
    </div>
  </div>

  <div class="col-xs-12 col-md-6 col-lg-4">
    <div class="account-div" [ngStyle]="{'color': social.twitter.color}">
      <img [src]='social.twitter.logo'>
      Twitter
      <button 
        [ngStyle]="{ 'color': social.twitter.color, 'border': '1px solid', 'border-color': social.twitter.color}"
        *ngIf="!socialPostingService?.socialPlatformsStatus?.twitter" 
        class="m-l-30" 
        nz-button nzSize="small" nzType="default" 
        [nzLoading]="twtloading"
        (click)="linkTwitter()"
      >
        {{ "Link" | translate }}
      </button>
      <button 
        [ngStyle]="{ 'color': social.twitter.color, 'border': '1px solid', 'border-color': social.twitter.color}"
        *ngIf="socialPostingService?.socialPlatformsStatus?.twitter" 
        class="m-l-30" 
        nz-button nzSize="small" nzType="default" 
        [nzLoading]="twtloading"
        (click)="unlinkTwitter()"
      >
        {{ "Unlink" | translate }}
      </button>
    </div>
  </div>
</div>



<!-- <nz-table *ngIf="fbAccountLinkStatus" #nzTable [nzData]="userFirendsList" nzTableLayout="fixed"
  class="friends-list-tbl">
  <thead>
    <tr>
      <th>{{'importName' | translate}}</th>
      <th>{{'importEmail' | translate}}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of nzTable.data">
      <td>{{ data.name}}</td>
      <td>{{ data.email}}</td>
    </tr>
  </tbody>
</nz-table> -->

<!-- <div class="share-sec text-center">
  <div class="addthis_inline_share_toolbox"></div>
</div> -->
