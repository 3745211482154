
<footer class="footer">
    <div class="footer-content">
        <!-- <div class="footer-media">
           <a href="#" class="fb"><i class="fab fa-facebook-f"></i></a>
           <a href="#" class="twitter"><i class="fab fa-twitter"></i></a>
           <a href="#" class="fb-messenger"><i class="fab fa-facebook-messenger"></i></a>
           <a href="#" class="whatsapp"><i class="fab fa-whatsapp"></i></a>
       </div>  -->
       <div class="container">
       <div class="row m-0">
            <div class="col-lg-12">
                <div class="footer-link text-center">
            </div>
            </div>
       </div>
    </div>
    </div>
    <p class="m-b-0 text-center">© MY TRENDING STORIES 2021</p>

</footer>