import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as firebase from 'firebase/app';
import { STAFF, MEMBER } from '../constants/member-constant';
import { AnalyticsService } from './analytics/analytics.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public showGuardLoader = false;
  public showLoader = false;
  loggedInUser;
  loggedInUserDetails;

  constructor(
    public afAuth: AngularFireAuth,
    public db: AngularFirestore,
    private http: HttpClient,
    private analyticsService: AnalyticsService,
  ) {
    this.afAuth.authState.subscribe((user) => {
      if (!user || !user.emailVerified) {
        if (environment && environment.isAnonymousUserEnabled) {
          this.afAuth.signInAnonymously().catch(function () {
            // console.log('anonymusly login');
          });
        }
      } else {
        this.loggedInUser = user;
      }
    });
  }
  getLoggedInUserDetails(uid: string = '') {
    return new Promise<any>((resolve) => {
      if (!uid && this.loggedInUser) {
        uid = this.loggedInUser.uid;
      }
      if (uid) {
        this.getMember(uid).subscribe((userData) => {
          resolve(userData);
        });
      } else {
        resolve(null);
      }
    });
  }
  getLoggedInUser(uid: string = '') {
    return new Promise<any>((resolve) => {
      if (!uid && this.loggedInUser) {
        uid = this.loggedInUser.uid;
      }
      if (uid) {
        this.get(uid).subscribe((userData) => {
          resolve(userData);
        });
      } else {
        resolve(null);
      }
    });
  }
  get(uid: string): Observable<any> {
    return this.db.doc(`users/${uid}`).valueChanges();
  }
  getMember(uid: string): Observable<any> {
    return this.db.doc(`members/${uid}`).valueChanges();
  }

  doRegister(email: string, password: string, displayName) {
    return new Promise<any>((resolve, reject) => {
      this.http
        .post(environment.baseAPIDomain + '/api/v1/auth/sign-up', {
          email: email,
          password: password,
          displayName: displayName,
        })
        .subscribe(
          async (data) => {
            (await this.afAuth.currentUser).sendEmailVerification();
            resolve(data);
          },
          (err) => reject(err),
        );
    });
  }

  doLogin(email: string, password: string) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.signInWithEmailAndPassword(email, password).then(
        async (res) => {
          if (res && !res.user.emailVerified) {
            (await this.afAuth.currentUser).sendEmailVerification();
          }

          if (res && !res.user.emailVerified) {
            (await this.afAuth.currentUser).sendEmailVerification();
          }

          this.analyticsService.logEvent('login', {
            user_uid: res.user.uid,
            user_email: res.user.email,
            user_name: res.user.displayName,
            provider_id:
              res.user.providerData.length > 0
                ? res.user.providerData[0].providerId
                : res.additionalUserInfo.providerId,
          });

          resolve(res);
        },
        (err) => reject(err),
      );
    });
  }

  signout() {
    return new Promise(async (resolve, reject) => {
      if (this.afAuth.currentUser) {
        const user = await this.afAuth.currentUser;

        this.afAuth.signOut().then(() => {
          this.analyticsService.logEvent('logout', {
            user_uid: user.uid,
            user_email: user.email,
            user_name: user.displayName,
            provider_id:
              user.providerData.length > 0
                ? user.providerData[0].providerId
                : user.providerId,
          });

          resolve();
        });
      } else {
        reject();
      }
    });
  }
  isLoggedIn() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }
  getAuthState() {
    return this.afAuth.authState;
  }
  checkDejangoCred(userData) {
    return this.http.post(environment.authService, userData);
  }

  getIdToken() {
    return this.afAuth.idToken;
  }

  getUserToken() {
    return this.afAuth.currentUser.then((u) => u.getIdToken(true));
  }
  async getCustomClaimData() {
    try {
      const user = await this.afAuth.currentUser;
      const idTokenResult = await user.getIdTokenResult();
      if (idTokenResult.claims.isAdmin) {
        return STAFF;
      } else {
        return MEMBER;
      }
    } catch (error) {
      return MEMBER;
    }
  }
  validateCaptcha(captchaToken) {
    return true;
    const httpOptions = { headers: { skip: 'true' } };

    return this.http.post(
      environment.baseAPIDomain + '/api/validateCaptcha',
      {
        token: captchaToken,
      },
      httpOptions,
    );
  }
  getLoginDetails() {
    return this.loggedInUser;
  }
  getSlug(displayName: string) {
    return this.slugify(displayName);
  }

  slugify(string) {
    return string
      .toString()
      .trim()
      .toLowerCase()
      .replace(/[^a-zA-Z ]/g, '')
      .replace(/\s+/g, '-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');
  }

  signInWithToken(customToken: string) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.signInWithCustomToken(customToken).then(
        (res) => {
          resolve(res);
        },
        (err) => reject(err),
      );
    });
  }

  revokeAllSessions() {
    const body = {
      uid: this.loggedInUser.uid,
    };
    return this.http.post(
      `${environment.baseAPIDomain}/api/v1/authshared/revokeAllSessions`,
      body,
    );
  }
}
