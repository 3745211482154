import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared.module';

import { SideNavComponent } from './backoffice/side-nav/side-nav.component';
import { FooterComponent } from './footer/footer.component';

import { SideNavDirective } from '../directives/side-nav.directive';
import { ThemeConstantService } from '../services/theme-constant.service';
import { HeaderBackofficeComponent } from './backoffice/header-backoffice/header-backoffice.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { FooterBackofficeComponent } from './backoffice/footer-backoffice/footer-backoffice.component';
import { ShareButtonComponent } from './backoffice/share-button/share-button.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

@NgModule({
  exports: [
    SideNavComponent,
    SideNavDirective,
    FooterComponent,
    HeaderBackofficeComponent,
    FooterBackofficeComponent,
    ShareButtonComponent,
  ],
  imports: [
    RouterModule,
    SharedModule,
    TranslateModule.forChild({
      useDefaultLang: true,
      isolate: false,
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ShareButtonsModule,
    ShareIconsModule,
  ],
  declarations: [
    SideNavComponent,
    SideNavDirective,
    FooterComponent,
    HeaderBackofficeComponent,
    FooterBackofficeComponent,
    ShareButtonComponent,
  ],
  providers: [ThemeConstantService],
})
export class TemplateModule {}
