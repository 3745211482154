import { AfterViewInit, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { BackofficeSocialPostingService } from '../../../backoffice/shared/services/backoffice-social-posting.service';
import { TwitterService } from '../../../backoffice/shared/services/social/twitter.service';
import { UserService } from 'src/app/shared/services/user.service';
declare var FB: any;

@Component({
  selector: 'app-link-social-account',
  templateUrl: './link-social-account.component.html',
  styleUrls: ['./link-social-account.component.scss']
})

export class LinkSocialAccountComponent implements OnInit, AfterViewInit {

  isLoaded = false;
  fbloading = false;
  linkLoading = false;
  twtloading = false;
  twitterAccountLinkStatus = false;
  userFirendsList = [];
  userDetails;
  social = {
    facebook: {
      color: '#3b5998',
      logo: './assets/images/social/facebook.png'
    },
    twitter: {
      color: '#00acee',
      logo: './assets/images/social/twitter.png'
    },
    linkedin: {
      color: '#00acee',
      logo: './assets/images/social/linkedin.png'
    }
  };

  constructor(
    public translate: TranslateService,
    public socialPostingService: BackofficeSocialPostingService,
    private twitterService: TwitterService,
    private userService: UserService
  ) { }

  ngOnInit(): void {


    this.userService.getCurrentUser().then((user) => {
      this.userService.get(user.uid).subscribe((userDetails) => {
        this.userDetails = userDetails;

      });
    });

    (window as any).fbAsyncInit = function () {
      FB.init({
        appId: environment.facebook.appId,
        cookie: true,
        xfbml: true,
        version: environment.facebook.version
      });

      FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      let js;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  ngAfterViewInit() {
    // this.getFBLoginStatus();
  }

  // ngAfterViewChecked(): void {
  //   if (!this.isLoaded) {
  //     delete window['addthis']
  //     setTimeout(() => { this.loadScript(); }, 100);
  //     this.isLoaded = true;
  //   }
  // }

  // loadScript() {
  //   let node = document.createElement('script');
  //   node.src = environment.addThisScript;
  //   node.type = 'text/javascript';
  //   node.async = true;
  //   node.charset = 'utf-8';
  //   document.getElementsByTagName('head')[0].appendChild(node);
  // }

  linkFacebook() {
    this.fbloading = true;
    FB.login((response) => {
      if (response.status === 'connected') {
        this.socialPostingService.saveAuthTokenToServer(
          'facebook',
          {
            token: response.authResponse.accessToken,
            expires_at: (response.authResponse?.data_access_expiration_time)
          }
        );
        this.socialPostingService.socialPlatformsStatus['facebook'] = true;
        this.fbloading = false;
        // this.getFacebookFriends();
      } else {
        // console.log('User login failed');
        this.fbloading = false;
      }
    }, {
      scope: 'email,public_profile,publish_to_groups,pages_manage_posts,pages_read_engagement,pages_show_list',
    });
  }

  unlinkFacebook() {
    this.fbloading = true;
    this.socialPostingService.deleteTokenInfo('facebook').then( data => {
      this.socialPostingService.socialPlatformsStatus['facebook'] = false;
      this.fbloading = false;
      FB.logout();
    }).catch( err => {
      this.fbloading = false;
    });
  }

  // getFBLoginStatus() {
  //   let self = this;
  //   if(FB) {
  //     FB.getLoginStatus(function (response) {
  //       self.statusChangeCallback(response);
  //     });
  //   }
  // }

  // statusChangeCallback(response) {
  //   if (response.status === 'connected') {
  //     this.getFacebookFriends();
  //   }
  // }

  // getFacebookFriends() {
  //   FB.api(
  //     `/me/friends`,
  //     'GET',
  //     {},
  //     function (response) {
  //       if (response.data) {
  //         this.userFirendsList = response.data;
  //       }
  //     }
  //   );
  // }

  linkLinkedin() {
    const clientId = environment.linkedin.clientId;
    const redirect = environment.consoleUrl + 'settings/redirect';
    const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000`;
    window.open(`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirect}&scope=w_member_social%20r_liteprofile%20`, '_self', params);
  }

  unlinkLinkedin() {
    this.linkLoading = true;
    this.socialPostingService.deleteTokenInfo('linkedin').then( data => {
      this.socialPostingService.socialPlatformsStatus['linkedin'] = false;
      this.linkLoading = false;
    }).catch( err => {
      this.linkLoading = false;
    });
  }

  linkTwitter() {
    this.twtloading = true;
    this.twitterService.getRedirectURL(this.userDetails.id).then(data => {
      console.log(data);
      this.twtloading = false;
    }).catch(err => {
      console.error(err);
      this.twtloading = false;
    });
  }

  unlinkTwitter() {
    this.twtloading = true;
    this.socialPostingService.deleteTokenInfo('twitter').then( data => {
      this.socialPostingService.socialPlatformsStatus['twitter'] = false;
      this.twtloading = false;
    }).catch( err => {
      this.twtloading = false;
    });
  }

}
