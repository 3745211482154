import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { BackofficeLayoutComponent } from './layouts/backoffice-layout/backoffice-layout.component';
import { BackofficeLayout_ROUTES } from './shared/routes/backoffice-layout.routes';
import { AuthService } from './shared/services/authentication.service';

const appRoutes: Routes = [
    {
        path: '',
        component: BackofficeLayoutComponent,
        children: BackofficeLayout_ROUTES
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            preloadingStrategy: PreloadAllModules,
            useHash: false,
            scrollPositionRestoration: 'enabled',
            onSameUrlNavigation: 'reload',
        })
    ],
    exports: [
        RouterModule
    ],
    providers: [AuthService]
})

export class AppRoutingModule { }
