<button class="m-r-10 share-btn" nz-button nzType="default" (click)="openShareModal()">
  {{'share' | translate}}
</button>

<div class="invite-modal-sec">
  <nz-modal [(nzVisible)]="isActiveModal" nzTitle="Share" (nzOnCancel)="closeShareModal()" (nzOnOk)="closeShareModal()">
    <div>
      <div *ngIf="userDetails" class="share-popup-body">
        <h2>Share your My Trending Stories profile link by copying it</h2>
        
        <nz-alert class="success-box" nzType="success" nzMessage="Link copied" nzCloseable *ngIf="isActiveSuccessBox">
        </nz-alert>
        <div class="badge-txt-box">
          <input nz-input type="text" value="{{baseUrl}}{{userDetails.slug}}" class="url-txt-box" readonly="readonly"
            #userinput>
          <button (click)="copyInputMessage(userinput)" type="button" class="share-txt-btn">Copy Link</button>
        </div>

        <hr />
        <div class="media-badge-group">
          <a href="{{baseUrl}}{{userDetails.slug}}" target="_blank" class="social-badge">
            <span class="logo-icon">M</span>
            <div>
              Follow us on
              <b>my trending stories</b>
            </div>
          </a>
          <a href="{{baseUrl}}{{userDetails.slug}}" target="_blank" class="social-badge" style="padding-top: 20px;">
            <svg width="40" height="40" style="max-width: 40px; margin-right: 10px;" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="20" cy="20" r="20" fill="#FF4020"/>
              <path d="M14.392 10.596C14.728 11.212 15.1107 11.9773 15.54 12.892C15.988 13.788 16.4453 14.768 16.912 15.832C17.3973 16.8773 17.8733 17.9507 18.34 19.052C18.8067 20.1533 19.2453 21.1893 19.656 22.16C20.0667 21.1893 20.5053 20.1533 20.972 19.052C21.4387 17.9507 21.9053 16.8773 22.372 15.832C22.8573 14.768 23.3147 13.788 23.744 12.892C24.192 11.9773 24.584 11.212 24.92 10.596H28.896C29.0827 11.884 29.2507 13.3307 29.4 14.936C29.568 16.5227 29.708 18.184 29.82 19.92C29.9507 21.6373 30.0627 23.364 30.156 25.1C30.268 26.836 30.3613 28.4693 30.436 30H26.18C26.124 28.1147 26.0493 26.0613 25.956 23.84C25.8627 21.6187 25.7227 19.3787 25.536 17.12C25.2 17.904 24.8267 18.772 24.416 19.724C24.0053 20.676 23.5947 21.628 23.184 22.58C22.792 23.532 22.4093 24.4467 22.036 25.324C21.6627 26.1827 21.3453 26.92 21.084 27.536H18.032C17.7707 26.92 17.4533 26.1827 17.08 25.324C16.7067 24.4467 16.3147 23.532 15.904 22.58C15.512 21.628 15.1107 20.676 14.7 19.724C14.2893 18.772 13.916 17.904 13.58 17.12C13.3933 19.3787 13.2533 21.6187 13.16 23.84C13.0667 26.0613 12.992 28.1147 12.936 30H8.68C8.75467 28.4693 8.83867 26.836 8.932 25.1C9.044 23.364 9.156 21.6373 9.268 19.92C9.39867 18.184 9.53867 16.5227 9.688 14.936C9.856 13.3307 10.0333 11.884 10.22 10.596H14.392Z" fill="white"/>
            </svg>
            <div>
              Visit my Profile on
              <b>my trending stories</b>
            </div>
          </a>
          <a href="{{baseUrl}}{{userDetails.slug}}" target="_blank" class="social-badge">
            <span class="logo-icon">M</span>
            <div>
              My profile on
              <b>my trending stories</b>
            </div>
          </a>
        </div>

        <h2>Copy code to show above icons on your website having link to your My Trending Stories profile</h2>

        <div class="row" style="margin-bottom: 7px;">
          <div class="col-6">
            <div class="row">
              <div class="col-5">
                <nz-alert nzType="success" nzMessage="Code Copied" nzCloseable *ngIf="isMTSCircleLogoCodeCopied"></nz-alert>
              </div>
              <div class="col-7" style="text-align: end;">
                <button nz-button nzType="primary" (click)="copyMTSCircleLogoCode(mtscirclecodetextarea)" type="button" style="width: 100%;">Copy Circle Logo Code</button>
              </div>
              <div class="col-12">
                <textarea #mtscirclecodetextarea rows="6" readonly="readonly" class="mtscodetextarea">{{mtsCircleLogoCode}}</textarea>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="row">
              <div class="col-5">
                <nz-alert nzType="success" nzMessage="Code Copied" nzCloseable *ngIf="isMTSSquareLogoCodeCopied"></nz-alert>
              </div>
              <div class="col-7" style="text-align: end;">
                <button nz-button nzType="primary" (click)="copyMTSSquareLogoCode(mtssquarecodetextarea)" type="button" style="width: 100%;">Copy Square Logo Code</button>
              </div>
              <div class="col-12">
                <textarea #mtssquarecodetextarea rows="6" readonly="readonly" class="mtscodetextarea">{{mtsSquareLogoCode}}</textarea>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="media-Icon">
          <h2>Share your My Trending Stories profile link with your Friends and Family</h2>
          <share-buttons theme="moon"
            [include]="['facebook','twitter','pinterest','linkedin','messenger','email','whatsapp','telegram']"
            [show]="8" url="{{baseUrl}}{{userDetails.slug}}" [autoSetMeta]="false"></share-buttons>
        </div>
      </div>
    </div>
  </nz-modal>
</div>