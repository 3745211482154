import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomersServiceService {
  customersServiceCollection = 'customers-service';
  members = 'members';
  constructor(
    private http: HttpClient,
    private db: AngularFirestore,
  ) {}

  getTickets(prop: string, value: string) {
    return this.db.collection(`${this.customersServiceCollection}`, ref =>
      ref.where(prop, '==', value)
    )
      .snapshotChanges()
      .pipe(
        map(actions => {
          const sortedData = actions.sort((a, b) => {
            const dataA: any = a.payload.doc.data();
            const dataB: any = b.payload.doc.data();
            const dateA = new Date(dataA.created_at);
            const dateB = new Date(dataB.created_at);
            return dateB.getTime() - dateA.getTime();
          });
          return sortedData.map(a => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        }),
      );
  }

  public createTicket(data: any) {
    return this.http.post(
      environment.baseAPIDomain + '/api/v1/customersService/addticket',
      data,
    );
  }

  public updateTicket(ticket_id: string, fields: any) {
    return this.http.put(
      environment.baseAPIDomain +
        '/api/v1/customersService/updateticket/' +
        ticket_id,
      fields
    );
  }

  public updateCustomersServiceData(ticket_id: string, fields: any) {
    return this.http.put(
      environment.baseAPIDomain +
        '/api/v1/customersService/updateticket/' +
        ticket_id,
      fields,
    );
  }

  public addReply(ticket_id: string, reply: string) {
    return this.http.put(
      environment.baseAPIDomain +
        '/api/v1/customersService/addReply/' +
        ticket_id,
      { reply: reply },
    );
  }

  public addRating(ticket_id: string, rating_number: number, comment: string) {
    return this.http.put(
      environment.baseAPIDomain + '/api/v1/customersService/addRating/' + ticket_id, {rating_number: rating_number, comment: comment}
    );
  }


  getEmps(prop: string, value: string) {
    return this.db
      .collection(`${this.members}`, (ref) => ref.where(prop, '==', value))
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        }),
      );
  }

  getEmployeeDetails(memberId: string): Observable<any> {
    return this.db.collection(`${this.members}`).doc(memberId).snapshotChanges()
      .pipe(
        take(1),
        map(action => {
          const data = action.payload.data();
          const id = action.payload.id;
          return Object.assign({}, { id: id }, data);
        })
      );
  }


  updateCustomerServiceEmployee1(empDetails) {
    return new Promise((resolve, reject) => {
      return this.http
        .put(
          environment.baseAPIDomain + '/api/v1/customersService/assignEmployee',
          empDetails,
          { observe: 'response' },
        )
        .subscribe(
          (empData) => {
            resolve(empData);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }

  updateCustomerServiceAdmin(adminDetails) {
    return new Promise((resolve, reject) => {
      return this.http
        .put(
          environment.baseAPIDomain + '/api/v1/customersService/assignAdmin',
          adminDetails,
          { observe: 'response' },
        )
        .subscribe(
          (empData) => {
            resolve(empData);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }

  updateCustomerServiceEmployee(empDetails) {
    return this.http.put(
      environment.baseAPIDomain + '/api/v1/customersService/assignEmployee',
      empDetails,
    );
  }

  public updateTicketStatus(ticket_id: string, fields: any) {
    return this.http.put(
      environment.baseAPIDomain +
        '/api/v1/customersService/updatestatus/' +
        ticket_id,
      fields,
    );
  }

  deleteTicket(ticket_id) {
    return this.http.delete(
      environment.baseAPIDomain + '/api/v1/customersService/' + ticket_id,
    );
  }

  public getStatusWiseTicketNumber() {
    return this.http.get(
      environment.baseAPIDomain + '/api/v1/customersService/getStatusWiseTicketNumber'
    );
  }

  public getEmployeeWiseTicketNumber() {
    return this.http.get(
      environment.baseAPIDomain + '/api/v1/customersService/getEmployeeWiseTicketNumber'
    );
  }

  public setCustomerServiceType(id: string, data: any) {
    return this.http.put(
      environment.baseAPIDomain + '/api/v1/members/setCustomerServiceType/' + id, data
    );
  }
}
