export const STAFF = 'staff';
export const STAFF_ACADEMY = 'staff-academy';
export const AUTHOR = 'author';
export const COMPANY = 'company';
export const CHARITY = 'charity';
export const FUNDRAISER = 'fundraiser';
export const ACCOUNT_UNVERIFIED = 'unverified';
export const ACCOUNT_VERIFIED = 'verified';

// Group 1
export const BLOGGER = 'blogger';
export const VLOGGER = 'vlogger';
export const PODCAST = 'podcaster';
export const GUESTPOST = 'guest-post';

// Group 2
export const MEMBER = 'member';

// Group 3
export const ADVISOR = 'advisor';
export const ONLINESTORE = 'online-store';
export const PAIDPREMIUMGROUP = 'paid-premium-group';
export const ONLINECOURSE = 'online-course';
export const INFLUENCERMARKETPLACE = 'influencer-marketing';
export const SERVICES = 'services';
export const JOB = 'job';

export const ECOMMERCE = 'ecommerce';
export const PROVIDER = 'provider';
export const INVESTMENT = 'investment';
export const VACATIONSRENTALS = 'vacations-rentals';
export const RESTAURANT = 'restaurants';
export const HOSTEVENT = 'host-event';
export const POLITICIAN = 'politician';
export const CUSTOMERSERVICE = 'customer-service';
export const CUSTOMERSERVICEADMIN = 'customer-service-admin';
export const HAIRSALON = 'hair-salon';
export const JOKES = 'jokes';
export const PRIVATECHEF = 'private-chef';
export const PRIVATEGROUP = 'private-group';
export const BEATMAKER = 'beat-maker';

